.app__navbar {
  background-color: #2d6b88 !important;
}

.navbar__form {
  background-color: #fff;
  width: 50%;
  margin: 0 auto;
  padding: 5px;
  padding-left: 20px;
  display: flex;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
}

.navbar__form input {
  border: none;
  flex: 1;
  border-radius: 20px;
  margin-left: 20px;
}
input:focus {
  outline: none;
}
