.ad_container {
  display: flex;
  flex-direction: column;
  text-align: center;

  height: 700px;
  width: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}
