@import url(https://fonts.googleapis.com/css2?family=Parisienne&display=swap);
.app__navbar {
  background-color: #2d6b88 !important;
}

.navbar__form {
  background-color: #fff;
  width: 50%;
  margin: 0 auto;
  padding: 5px;
  padding-left: 20px;
  display: flex;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
}

.navbar__form input {
  border: none;
  flex: 1 1;
  border-radius: 20px;
  margin-left: 20px;
}
input:focus {
  outline: none;
}

.footer {
  background-color: #2d6b88 !important;
  width: 100%;
  text-align: center;
  color: #fff;
  padding: 50px 0;
  position: relative;
  bottom: -100%;
}

.social-links a {
  font-size: 1.5em;
  color: #fff;
  margin-right: 5px;
}

.social-links a:hover {
  color: #bd2998;
  text-decoration: none;
}

.signin {
  display: grid;
  place-items: center;
  margin: 50px 0;
}
.signin__form {
  width: 50%;
  background-color: rgba(255, 255, 255, 0.829);
  padding: 20px;
  border-radius: 10px;
  font-family: Georgia, "Times New Roman", Times, serif;
  color: black;
  /* @media #{$mobile_device} {
      width: 80%;
      margin-top: 10px;
    } */
}

.signin__form h4 {
  color: #1d4354;
  margin-bottom: 20px;
}
.signin__selectUser {
  margin-bottom: 20px;
}
.signin__selectUser label {
  display: inline-block;
  width: 135px;
  background-color: #e4e4e4;
  color: rgba(0, 0, 0, 0.6);
  font-size: 20px;
  text-align: center;
  padding: 6px 14px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out;
  margin: 0 !important;
}

.signin__selectUser label:hover {
  cursor: pointer;
}

.signin__selectUser input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}

.signin__selectUser input:checked + label {
  background-color: #bd2998;
  color: #fff;
  box-shadow: none;
}

.signin__selectUser label:first-of-type {
  /* border-radius: 0px 10px 10px 0px; */
  /* border: 10px solid red; */
}

.signin__selectUser label:last-of-type {
  border-radius: 10px 10px 10px 10px;
}

.form-group {
}

.signin-up-alts {
  margin: 25px 0;
  font-size: 17px;
  text-align: center;
}

.sign__container {
  background-image: url(/static/media/shutterstock_615551258.9d9ac8ae.jpg);
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  color: #fff;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  /* Specific to this particular image */
  img.bg {
    left: 50%;
    margin-left: -512px; /* 50% */
  }
}
.app__container {
  font-size: 25px;
  font-weight: bold;
}

.submitbtn {
  width: 100%;
  min-width: 280px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border: solid 2px #008891;
  border-radius: 4px;
  background: #0f3057;
  cursor: pointer;
}

.submitbtn:hover {
  background-color: #00587a;
  color: #fff;
}

.welcome-text {
  font-family: "Parisienne", cursive;
  text-align: center;
  position: relative;
  font-size: 3rem;
}

.ad_container {
  display: flex;
  flex-direction: column;
  text-align: center;

  height: 700px;
  width: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.friends_list {
  display: flex;
  flex-direction: column;
  text-align: center;

  height: 700px;
  width: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.avatar {
  text-align: center;

  height: 50px;
  width: 50px;
}

.container {
  height: "400px";
  width: "600px";
  overflow-y: scroll;
  overflow-x: hidden;
}

.messages {
  display: block;
}

* {
  margin: 0 auto;
  padding: 0 auto;
}

.avatar {
  height: "25px" !important;
  width: "25px" !important;
}

.my_photos_container {
  display: flex;
  flex-direction: row;
}

.friends_list_container {
  display: flex;
  flex-direction: row;
}

.profile_pic {
  text-align: center;
  height: 400px;
  width: 400px;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

body {
  background-color: #f1f2f4 !important;
}

.app__container {
  min-height: 70vh;
}

.primary-btn {
  background-color: #bd2998 !important;
  color: #fff !important;
  border: none !important;
}

.primary-btn:hover {
  background-color: #8a216e !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.appLoading {
  font-size: 2rem;
  color: black;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

