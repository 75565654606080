@import url("https://fonts.googleapis.com/css2?family=Parisienne&display=swap");

.sign__container {
  background-image: url("../images/shutterstock_615551258.jpg");
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  color: #fff;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  /* Specific to this particular image */
  img.bg {
    left: 50%;
    margin-left: -512px; /* 50% */
  }
}
.app__container {
  font-size: 25px;
  font-weight: bold;
}

.submitbtn {
  width: 100%;
  min-width: 280px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border: solid 2px #008891;
  border-radius: 4px;
  background: #0f3057;
  cursor: pointer;
}

.submitbtn:hover {
  background-color: #00587a;
  color: #fff;
}

.welcome-text {
  font-family: "Parisienne", cursive;
  text-align: center;
  position: relative;
  font-size: 3rem;
}
