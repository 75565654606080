.signin {
  display: grid;
  place-items: center;
  margin: 50px 0;
}
.signin__form {
  width: 50%;
  background-color: rgba(255, 255, 255, 0.829);
  padding: 20px;
  border-radius: 10px;
  font-family: Georgia, "Times New Roman", Times, serif;
  color: black;
  /* @media #{$mobile_device} {
      width: 80%;
      margin-top: 10px;
    } */
}

.signin__form h4 {
  color: #1d4354;
  margin-bottom: 20px;
}
.signin__selectUser {
  margin-bottom: 20px;
}
.signin__selectUser label {
  display: inline-block;
  width: 135px;
  background-color: #e4e4e4;
  color: rgba(0, 0, 0, 0.6);
  font-size: 20px;
  text-align: center;
  padding: 6px 14px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out;
  margin: 0 !important;
}

.signin__selectUser label:hover {
  cursor: pointer;
}

.signin__selectUser input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}

.signin__selectUser input:checked + label {
  background-color: #bd2998;
  color: #fff;
  box-shadow: none;
}

.signin__selectUser label:first-of-type {
  /* border-radius: 0px 10px 10px 0px; */
  /* border: 10px solid red; */
}

.signin__selectUser label:last-of-type {
  border-radius: 10px 10px 10px 10px;
}

.form-group {
}

.signin-up-alts {
  margin: 25px 0;
  font-size: 17px;
  text-align: center;
}
