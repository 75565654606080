.avatar {
  text-align: center;

  height: 50px;
  width: 50px;
}

.container {
  height: "400px";
  width: "600px";
  overflow-y: scroll;
  overflow-x: hidden;
}

.messages {
  display: block;
}
