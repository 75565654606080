.footer {
  background-color: #2d6b88 !important;
  width: 100%;
  text-align: center;
  color: #fff;
  padding: 50px 0;
  position: relative;
  bottom: -100%;
}

.social-links a {
  font-size: 1.5em;
  color: #fff;
  margin-right: 5px;
}

.social-links a:hover {
  color: #bd2998;
  text-decoration: none;
}
