.my_photos_container {
  display: flex;
  flex-direction: row;
}

.friends_list_container {
  display: flex;
  flex-direction: row;
}

.profile_pic {
  text-align: center;
  height: 400px;
  width: 400px;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
